import Dexie, { Table } from "dexie";

export interface UserSitesItemType {
  id: string;
  admin: 0 | 1;
  owner: 0 | 1;
}

class NexusDexie extends Dexie {
  userSites!: Table<UserSitesItemType>;

  constructor() {
    super("NexusDatabase");
    //version is multiplied by 10 in indexDB
    this.version(1).stores({
      userSites: "id,admin,owner", // Primary key and indexed props
    });
  }
}

const nexusDB = new NexusDexie();

export { nexusDB };
