import React from "react";
import { Container, Grid, Link as LinkMui, Typography } from "@mui/material";
import { Box } from "@mui/system";

import { ReactComponent as IonageLogoFull } from "@assets/images/ionage-logo-full.svg";
import { IonButton } from "@components/ionButton";
import bgImage from "@assets/images/not-found-bg.svg";
const NotFound = ({
  disableNavigation = false,
  message = "Page not found",
}: {
  disableNavigation?: boolean;
  message?: string;
}) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${bgImage})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom right",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Container
        disableGutters={true}
        component="main"
        maxWidth="xl"
        sx={{
          outline: "none",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <IonageLogoFull
          style={{
            height: "2.7rem",
            margin: "2rem",
          }}
        />
        <Box
          maxWidth={378}
          sx={{
            margin: "6rem 8rem",
          }}
        >
          <Typography
            variant="h4"
            sx={{ fontSize: 34, fontWeight: 500, marginBottom: 3 }}
          >
            {message}
          </Typography>
          <Typography component="p" sx={{ fontWeight: 500 }}>
            Something went wrong. Please check your url and try again.
          </Typography>
          <Grid container>
            <Grid item xs={6}>
              {!disableNavigation && (
                <LinkMui href="/" underline="none">
                  <IonButton
                    variant="outlined"
                    fullWidth
                    color="button"
                    sx={(theme) => ({
                      height: 48,
                      mt: 2,
                    })}
                    aria-label="Discard"
                  >
                    {"Go to home"}
                  </IonButton>
                </LinkMui>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default NotFound;
