import { IconButton as MIconButton, IconButtonProps } from "@mui/material";
import React, { memo } from "react";

export interface IonIconButtonType extends IconButtonProps {}

const IonIconButton = ({ children, ...buttonProps }: IonIconButtonType) => {
  return <MIconButton {...buttonProps}>{children}</MIconButton>;
};

export default memo(IonIconButton);
