import {
  Container as MuiContainer,
  ContainerTypeMap,
  SxProps,
  Theme,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { FC, memo, ReactNode } from "react";

interface CustomContainerType
  extends Partial<OverridableComponent<ContainerTypeMap<{}, "main">>> {
  children?: ReactNode;
  sx?: SxProps<Theme> | undefined;
}

const CustomContainer: FC<CustomContainerType> = ({
  children,
  sx,
  ...containerProps
}) => {
  return (
    <MuiContainer
      disableGutters={true}
      component="main"
      maxWidth={false}
      sx={{ display: "flex", flexDirection: "column", ...sx }}
      {...containerProps}
    >
      {children}
    </MuiContainer>
  );
};

export default memo(CustomContainer);
