import { createSlice } from "@reduxjs/toolkit";
import { Tenant } from "../types/Tenant";

export interface TenantState {
  id?: Tenant["id"];
  name?: Tenant["name"];
  email?: Tenant["email"];
  address?: Tenant["address"];
  subdomain?: Tenant["subdomain"];
  logo?: string;
}

const initialState: TenantState = {};

export const tenantSlice = createSlice({
  name: "tenant",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type === "CHANGE_TENANT",
      (state, action) => {
        return { ...state, ...action.payload };
      }
    );
  },
});

export default tenantSlice.reducer;
