import { IonButton, IonButtonType } from "@components/ionButton";
import React, { memo, useState } from "react";

export interface IonButtonMultiVariantType extends IonButtonType {}

const IonButtonMultiVariant = ({
  children,
  ...buttonProps
}: IonButtonMultiVariantType) => {
  const [buttonHovered, setbuttonHovered] = useState<boolean>(false);
  return (
    <IonButton
      {...buttonProps}
      variant={buttonHovered ? "contained" : "outlined"}
      onMouseEnter={(event) => {
        setbuttonHovered(true);
        buttonProps?.onMouseEnter && buttonProps.onMouseEnter(event);
      }}
      onMouseLeave={(event) => {
        setbuttonHovered(false);
        buttonProps?.onMouseLeave && buttonProps.onMouseLeave(event);
      }}
    >
      {children}
    </IonButton>
  );
};

export default memo(IonButtonMultiVariant);
