import axios, { AxiosError, AxiosResponse } from "axios";

const commonConfig = {
  timeout: 5 * 60 * 1000,
  headers: {
    "Content-Type": "application/json",
  },
};

export const nexusAPI = (token: string = "") => {
  let BASE_URL = `${process.env.REACT_APP_API_PROTOCOL}://${process.env.REACT_APP_API_HOST}`;

  if (process.env.REACT_APP_API_PORT) {
    BASE_URL += `:${process.env.REACT_APP_API_PORT}`;
  }

  const nexusInstance = axios.create({
    ...commonConfig,
    baseURL: BASE_URL,
  });

  nexusInstance.interceptors.request.use(
    (config) => {
      if (token && config.headers) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  nexusInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      const { response, request } = error || {};
      return Promise.reject(response || { ...request, status: 400 });
    }
  );
  return nexusInstance;
};

export const ionageAPI = (token: string = "") => {
  const ionageInstance = axios.create({
    ...commonConfig,
    baseURL: process.env.REACT_APP_IONAGE_SERVICE_API,
  });

  ionageInstance.interceptors.request.use(
    (config) => {
      if (token && config.headers) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  ionageInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      const { response, request } = error || {};
      return Promise.reject(response || request);
    }
  );
  return ionageInstance;
};

export const commonAPI = () => {
  const commonInstance = axios.create({
    ...commonConfig,
    baseURL: "",
  });
  commonInstance.interceptors.request.use(
    (config) => {
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  commonInstance.interceptors.response.use(
    (response: AxiosResponse) => {
      return response;
    },
    (error: AxiosError) => {
      const { response, request } = error || {};
      return Promise.reject(response || request);
    }
  );
  return commonInstance;
};

interface responseError extends AxiosResponse, Error {
  data:
    | {
        error?: string;
        message?: string;
      }
    | string;
}

export const getErrorMessage = (
  error: responseError,
  errorheader: string = ""
) => {
  const { data, message } = error || {};
  if (typeof data === "string") {
    const FINAL_ERROR = data || message;
    process.env.NODE_ENV === "development" &&
      console.log("ERROR", errorheader, FINAL_ERROR);
    return FINAL_ERROR;
  } else {
    const { error: apiError = "", message: apiMessage = "" } = data || {};
    const FINAL_ERROR = apiError || apiMessage || message;
    process.env.NODE_ENV === "development" &&
      console.log("ERROR", errorheader, FINAL_ERROR);
    return FINAL_ERROR;
  }
};
