import { PaletteColor, PaletteColorOptions } from "@mui/material";
import createTheme from "@mui/material/styles/createTheme";
import { lightTheme } from "./LightTheme";

const RootTheme = createTheme(lightTheme);

declare module "@mui/material/styles" {
  interface Palette {
    field?: PaletteColor;
    button?: PaletteColor;
    tertiary?: PaletteColor;
  }
  interface PaletteOptions {
    field?: PaletteColorOptions;
    button?: PaletteColorOptions;
    tertiary?: PaletteColorOptions;
  }
  interface TypeBackground {
    dropdown: string;
    card: string;
    tableHead: string;
  }
}

interface CustomColor {
  field: true;
  button: true;
  tertiary?: true;
}

declare module "@mui/material/Button" {
  interface ButtonPropsColorOverrides extends CustomColor {}
}

declare module "@mui/material/Chip" {
  interface ChipPropsColorOverrides extends CustomColor {}
}

declare module "@mui/material/IconButton" {
  interface IconButtonPropsColorOverrides extends CustomColor {}
}

declare module "@mui/material/Fab" {
  interface FabPropsColorOverrides extends CustomColor {}
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsColorOverrides extends CustomColor {}
}

declare module "@mui/material/FormControl" {
  interface FormControlPropsColorOverrides extends CustomColor {}
}

declare module "@mui/material/InputBase" {
  interface InputBasePropsColorOverrides extends CustomColor {}
}

declare module "@mui/material/Checkbox" {
  interface CheckboxPropsColorOverrides extends CustomColor {}
}

declare module "@mui/material/ToggleButtonGroup" {
  interface ToggleButtonGroupPropsColorOverrides extends CustomColor {}
}

declare module "@mui/material/CircularProgress" {
  interface CircularProgressPropsColorOverrides extends CustomColor {}
}

export { RootTheme };
