type IonColorNames =
  | "ionMidnightGreen"
  | "ionDiamond"
  | "ionPastelRed"
  | "ionMaastrichtBlue"
  | "ionImerialRed"
  | "ionGamboge"
  | "ionAzure"
  | "ionKellyGreen"
  | "ionGrey";

interface IonColorsVariants {
  50: string;
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
}

export const IonColors: { [key in IonColorNames]: IonColorsVariants } = {
  ionMidnightGreen: {
    50: "#E1F4FD",
    100: "#B5E3F9",
    200: "#85D1F2",
    300: "#58BFE9",
    400: "#3EB3E0",
    500: "#35A7D6",
    600: "#2D98C3",
    700: "#2185A8",
    800: "#16728F",
    900: "#005163",
  },
  ionDiamond: {
    50: "#E5FDFF",
    100: "#BFFBFF",
    200: "#97F9FF",
    300: "#73F4FF",
    400: "#5EEFFE",
    500: "#56EAFE",
    600: "#51D9EA",
    700: "#4AC2CE",
    800: "#44ADB5",
    900: "#398888",
  },
  ionPastelRed: {
    50: "#FFEAEF",
    100: "#FFCAD3",
    200: "#FF9398",
    300: "#FF646E",
    400: "#FF3048",
    500: "#FF012A",
    600: "#FF002B",
    700: "#EF0026",
    800: "#E1001E",
    900: "#D10010",
  },
  ionMaastrichtBlue: {
    50: "#DAF0F9",
    100: "#B8D7DE",
    200: "#94B9C4",
    300: "#709CA9",
    400: "#558795",
    500: "#377281",
    600: "#2C6471",
    700: "#1E505C",
    800: "#113D48",
    900: "#002932",
  },
  ionImerialRed: {
    50: "#FFEBEF",
    100: "#FFCCD5",
    200: "#F4979F",
    300: "#EC6D79",
    400: "#F84658",
    500: "#FF2A3F",
    600: "#F01E3E",
    700: "#DE0D37",
    800: "#D1002F",
    900: "#C30023",
  },
  ionGamboge: {
    50: "#FDF7E1",
    100: "#FBEAB4",
    200: "#F9DD83",
    300: "#F7D153",
    400: "#F5C630",
    500: "#F4BC1B",
    600: "#F3AF15",
    700: "#F39D12",
    800: "#F28D0F",
    900: "#F16F0B",
  },
  ionAzure: {
    50: "#E2F1FF",
    100: "#B9DCFF",
    200: "#8AC7FF",
    300: "#56B0FF",
    400: "#279FFF",
    500: "#008EFF",
    600: "#0080FF",
    700: "#146DEA",
    800: "#1C5BD8",
    900: "#2239B8",
  },
  ionKellyGreen: {
    50: "#ECF8E6",
    100: "#D0EDC1",
    200: "#B0E099",
    300: "#8FD46F",
    400: "#74CB4D",
    500: "#58C127",
    600: "#48B11F",
    700: "#2F9D14",
    800: "#0B8906",
    900: "#006700",
  },
  ionGrey: {
    50: "#FAFAFA",
    100: "#F5F5F5",
    200: "#EEEEEE",
    300: "#E0E0E0",
    400: "#BDBDBD",
    500: "#9E9E9E",
    600: "#757575",
    700: "#616161",
    800: "#424242",
    900: "#212121",
  },
};

type IonCommonColorNames = "black" | "white";

export const IonCommonColors: { [key in IonCommonColorNames]: string } = {
  black: "#000000",
  white: "#FFFFFF",
};
