import { Action, Entity } from "../types/Authorization";
import { UserRole, UserToken } from "../types/User";

import { SiteArea } from "../types/SiteArea";
import { TenantComponents } from "../types/Tenant";
import jwtDecode from "jwt-decode";
import { nexusDB } from "./nexusDB";

var decodedToken: { token: string; decoded: UserToken | undefined } = {
  decoded: undefined,
  token: "",
};

export const getLoggedUser = () => {
  const token = localStorage.getItem("token");
  if (!token) {
    return undefined;
  }
  if (decodedToken.token === token) {
    const { decoded } = decodedToken || {};
    return decoded;
  }
  const USER = jwtDecode<UserToken>(token) || {};
  decodedToken = { decoded: USER, token: token };
  return USER;
};

export const getTenantName = () => {
  const { tenantName } = getLoggedUser() || {};
  return tenantName;
};

export const canAccess = (resource: Entity, action: Action) => {
  const { scopes } = getLoggedUser() || {};
  if (!scopes) {
    return false;
  }
  return scopes.includes(`${resource}:${action}`);
};

const isActive = (componentName: TenantComponents) => {
  const { activeComponents } = getLoggedUser() || {};
  if (activeComponents) {
    return activeComponents.includes(componentName);
  }
  return false;
};

export const canListInvoicesBilling = () => {
  return canAccess(Entity.INVOICE, Action.LIST);
};

export const canListCars = () => {
  return canAccess(Entity.CAR, Action.LIST);
};

export const canReadCar = () => {
  return canAccess(Entity.CAR, Action.READ);
};

export const canUpdateCar = () => {
  return canAccess(Entity.CAR, Action.UPDATE);
};

export const canDeleteCar = () => {
  return canAccess(Entity.CAR, Action.DELETE);
};

export const canUpdateChargingStation = () => {
  return canAccess(Entity.CHARGING_STATION, Action.UPDATE);
};

export const canListChargingStations = () => {
  return canAccess(Entity.CHARGING_STATION, Action.LIST);
};

export const canListChargingStationsInError = () => {
  return canAccess(Entity.CHARGING_STATION, Action.IN_ERROR);
};

export const canListAssets = () => {
  return canAccess(Entity.ASSET, Action.LIST);
};

export const canListLogs = () => {
  if (canAccess(Entity.LOGGING, Action.LIST)) {
    if (isSuperAdmin() || isAdmin() || isPlatform()) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const canListAssetsInError = () => {
  return canAccess(Entity.ASSET, Action.IN_ERROR);
};

export const canListChargingProfiles = () => {
  return canAccess(Entity.CHARGING_PROFILE, Action.LIST);
};

export const canReadCompany = () => {
  return canAccess(Entity.COMPANY, Action.READ);
};

export const canUpdateCompany = () => {
  return canAccess(Entity.COMPANY, Action.UPDATE);
};

export const canCreateCompany = () => {
  return canAccess(Entity.COMPANY, Action.CREATE);
};

export const canDeleteCompany = () => {
  return canAccess(Entity.COMPANY, Action.DELETE);
};

export const canListCompanies = () => {
  return canAccess(Entity.COMPANY, Action.LIST);
};

export const canListSites = () => {
  return canAccess(Entity.SITE, Action.LIST);
};

export const canReadSite = () => {
  return canAccess(Entity.SITE, Action.READ);
};

export const canCreateSite = () => {
  return canAccess(Entity.SITE, Action.CREATE);
};

export const canDeleteSite = () => {
  return canAccess(Entity.SITE, Action.DELETE);
};

export const canUpdateSite = () => {
  return canAccess(Entity.SITE, Action.UPDATE);
};

export const canListSiteAreas = () => {
  return canAccess(Entity.SITE_AREA, Action.LIST);
};

export const canReadSiteArea = () => {
  return canAccess(Entity.SITE_AREA, Action.READ);
};

export const canAssignUsersSites = () => {
  return canAccess(Entity.USERS_SITES, Action.ASSIGN);
};

export const canUnassignUsersSites = () => {
  return canAccess(Entity.USERS_SITES, Action.UNASSIGN);
};

export const canListUsersSites = () => {
  return canAccess(Entity.USERS_SITES, Action.LIST);
};

export const canCreateSiteArea = () => {
  return canAccess(Entity.SITE_AREA, Action.CREATE);
};

export const canUpdateSiteArea = () => {
  return canAccess(Entity.SITE_AREA, Action.UPDATE);
};

export const canDeleteSiteArea = () => {
  return canAccess(Entity.SITE_AREA, Action.DELETE);
};

export const canListSettings = () => {
  return canAccess(Entity.SETTING, Action.LIST);
};

export const canReadSetting = () => {
  return canAccess(Entity.SETTING, Action.READ);
};

export const canDownloadInvoice = (userId: string) => {
  if (canAccess(Entity.INVOICE, Action.DOWNLOAD)) {
    if (isAdmin()) {
      return true;
    }
    const { id } = getLoggedUser() || {};
    if (id === userId) {
      return true;
    }
  }
  return false;
};

export const canDeleteTransaction = () => {
  return canAccess(Entity.TRANSACTION, Action.DELETE);
};

export const canExportTransactions = () => {
  return canAccess(Entity.TRANSACTION, Action.EXPORT);
};

export const canListUsers = () => {
  return canAccess(Entity.USER, Action.LIST);
};

export const canListUsersInError = () => {
  return canAccess(Entity.USER, Action.IN_ERROR);
};

export const canDeleteUser = () => {
  return canAccess(Entity.USER, Action.DELETE);
};

export const canImportUsers = () => {
  return canAccess(Entity.USER, Action.IMPORT);
};

export const canListTags = () => {
  return canAccess(Entity.TAG, Action.LIST);
};

export const canImportTags = () => {
  return canAccess(Entity.TAG, Action.IMPORT);
};

export const canExportTags = () => {
  return canAccess(Entity.TAG, Action.EXPORT);
};

export const canUpdateUser = () => {
  return canAccess(Entity.USER, Action.UPDATE);
};

export const canCreateUser = () => {
  return canAccess(Entity.USER, Action.CREATE);
};

export const canExportUsers = () => {
  return canAccess(Entity.USER, Action.EXPORT);
};

export const canExportChargingStations = () => {
  return canAccess(Entity.CHARGING_STATION, Action.EXPORT);
};

export const canSynchronizeBillingUser = () => {
  return canAccess(Entity.USER, Action.SYNCHRONIZE_BILLING_USER);
};

export const canRefundTransaction = () => {
  return canAccess(Entity.TRANSACTION, Action.REFUND_TRANSACTION);
};

export const canSynchronizeInvoices = () => {
  return canAccess(Entity.INVOICE, Action.SYNCHRONIZE);
};

export const canStopTransaction = async (
  siteID: SiteArea["siteID"],
  badgeID: string
) => {
  if (canAccess(Entity.CHARGING_STATION, Action.REMOTE_STOP_TRANSACTION)) {
    const { tagIDs } = getLoggedUser() || {};
    if (tagIDs && tagIDs.includes(badgeID)) {
      return true;
    }
    if (isActive(TenantComponents.ORGANIZATION)) {
      return (await isSiteAdmin(siteID)) || isAdmin();
    }
    return isAdmin();
  }
  return false;
};

export const canStartTransaction = async (siteArea: SiteArea) => {
  if (canAccess(Entity.CHARGING_STATION, Action.REMOTE_START_TRANSACTION)) {
    if (isActive(TenantComponents.ORGANIZATION)) {
      if (!siteArea) {
        return false;
      }
      if (
        !siteArea.accessControl ||
        (await isSiteAdmin(siteArea.siteID)) ||
        isAdmin()
      ) {
        return true;
      }
      try {
        const userSite = await nexusDB.userSites.get(siteArea.siteID);
        return !!userSite;
      } catch {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const canUnlockConnector = async (siteArea: SiteArea) => {
  if (canAccess(Entity.CHARGING_STATION, Action.UNLOCK_CONNECTOR)) {
    if (isActive(TenantComponents.ORGANIZATION)) {
      if (!siteArea) {
        return false;
      }
      if (
        !siteArea.accessControl ||
        (await isSiteAdmin(siteArea.siteID)) ||
        isAdmin()
      ) {
        return true;
      }
      try {
        const userSite = await nexusDB.userSites.get(siteArea.siteID);
        return !!userSite;
      } catch {
        return false;
      }
    }
    return true;
  }
  return false;
};

export const canReadTransaction = async (
  siteArea: SiteArea,
  badgeID: string
) => {
  if (canAccess(Entity.TRANSACTION, Action.READ)) {
    const { tagIDs } = getLoggedUser() || {};
    if (tagIDs && tagIDs.includes(badgeID)) {
      return true;
    }
    if (isActive(TenantComponents.ORGANIZATION) && siteArea) {
      return (
        isAdmin() ||
        (await isSiteAdmin(siteArea.siteID)) ||
        (isDemo() && (await isSiteUser(siteArea.siteID)))
      );
    }
    return isAdmin() || isDemo();
  }
  return false;
};

export const canListTransactions = () => {
  return canAccess(Entity.TRANSACTION, Action.LIST);
};

export const canListTransactionsInError = () => {
  return canAccess(Entity.TRANSACTION, Action.IN_ERROR);
};

export const canCreateToken = () => {
  return canAccess(Entity.REGISTRATION_TOKEN, Action.CREATE);
};

export const canListTokens = () => {
  return canAccess(Entity.REGISTRATION_TOKEN, Action.LIST);
};

export const canUpdateToken = () => {
  return canAccess(Entity.REGISTRATION_TOKEN, Action.UPDATE);
};

export const canDeleteToken = () => {
  return canAccess(Entity.REGISTRATION_TOKEN, Action.DELETE);
};

export const canListPaymentMethods = () => {
  return canAccess(Entity.PAYMENT_METHOD, Action.LIST);
};

// TODO: Should return different response if admin is on its own pm or not ?
export const canCreatePaymentMethod = () => {
  return canAccess(Entity.PAYMENT_METHOD, Action.CREATE);
};

// TODO: Use canRead when we have the list of payment method
export const canReadPaymentMethod = () => {
  return canAccess(Entity.PAYMENT_METHOD, Action.READ);
};

export const canListPricingDefinition = () => {
  return canAccess(Entity.PRICING_DEFINITION, Action.LIST);
};

export const canCreatePricingDefinition = () => {
  return canAccess(Entity.PRICING_DEFINITION, Action.CREATE);
};

export const canDeletePricingDefinition = () => {
  return canAccess(Entity.PRICING_DEFINITION, Action.DELETE);
};

export const isSiteAdmin = async (siteID: string) => {
  if (isAdmin()) {
    return true;
  }
  try {
    const { admin } = (await nexusDB.userSites.get(siteID)) || {};
    return !!admin;
  } catch {
    return false;
  }
};

export const isSiteOwner = async (siteID: string) => {
  if (isAdmin()) {
    return true;
  }
  try {
    const { owner } = (await nexusDB.userSites.get(siteID)) || {};
    return !!owner;
  } catch {
    return false;
  }
};

export const isSiteUser = async (siteID: string) => {
  if (isAdmin()) {
    return true;
  }
  if (canAccess(Entity.SITE, Action.READ)) {
    try {
      const userSite = await nexusDB.userSites.get(siteID);
      return !!userSite;
    } catch {
      return false;
    }
  }
  return false;
};

export const getRole = () => {
  const { role } = getLoggedUser() || {};
  return role;
};

export const getTenantID = () => {
  const { tenantID } = getLoggedUser() || {};
  return tenantID;
};

export const getUserID = () => {
  const { id } = getLoggedUser() || {};
  return id;
};

export const isAdmin = () => {
  return getRole() === UserRole.ADMIN;
};

export const isSuperAdmin = () => {
  return getRole() === UserRole.SUPER_ADMIN;
};

export const isBasic = () => {
  return getRole() === UserRole.BASIC;
};

export const isDemo = () => {
  return getRole() === UserRole.DEMO;
};

export const isBusiness = () => {
  return getRole() === UserRole.BUSINESS;
};

export const isPlatform = () => {
  return getRole() === UserRole.PLATFORM;
};

//CUSTOM IONAGE PERMISSIONS

export const canListStations = () => {
  return canListSites() && canListSiteAreas();
};

export const canReadStation = () => {
  return canReadSite() && canReadSiteArea();
};

export const canCreateStation = () => {
  return canCreateSite() && canCreateSiteArea();
};

export const canDeleteStation = () => {
  return canDeleteSite() && canDeleteSiteArea();
};

export const canUpdateStation = async (siteID: string) => {
  return (
    canUpdateSite() &&
    canUpdateSiteArea() &&
    ((await isSiteAdmin(siteID)) || (await isSiteOwner(siteID)))
  );
};

export const canListAnalytics = () => {
  if (isAdmin() || !isSuperAdmin()) {
    return true;
  }
  return canAccess(Entity.TRANSACTION, Action.UPDATE);
};

export const canListSessions = () => {
  if (isAdmin() || !isSuperAdmin()) {
    return true;
  }
  return canAccess(Entity.TRANSACTION, Action.LIST);
};

export const canListHome = async () => {
  if (isAdmin() || isSuperAdmin() || isBusiness()) {
    return true;
  }
  try {
    const userSite = await nexusDB.userSites.where("owner").equals(1).count();
    return isBasic() && userSite > 0;
  } catch (error) {
    return false;
  }
};
