import { createSlice } from "@reduxjs/toolkit";

export interface ErrorState {
  [key: string]: string | Error;
}

const initialState: ErrorState = {};

export const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type.endsWith("REQUEST") || action.type.endsWith("FAIL"),
      (state, action) => {
        const matches = /(.*)_(REQUEST|FAIL)/.exec(action.type);
        if (!matches) {
          return state;
        }
        const [, requestName, requestState] = matches;
        if (requestState === "FAIL") {
          const { data, message } = action.payload || {};
          const { error: apiError = "" } = data || {};
          return {
            ...state,
            [requestName]: apiError || message || JSON.stringify(data) || "",
          };
        }
        const { [requestName]: prevErrorState, ...rest } = state;
        return rest;
      }
    );
  },
});

export default errorSlice.reducer;
