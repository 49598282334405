import { createSlice } from "@reduxjs/toolkit";

export interface LoadingState {
  [key: string]: boolean;
}

const initialState: LoadingState = {};

export const loadingSlice = createSlice({
  name: "loading",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) =>
        action.type.endsWith("REQUEST") ||
        action.type.endsWith("SUCCESS") ||
        action.type.endsWith("FAIL"),
      (state, action) => {
        const matches = /(.*)_(REQUEST|SUCCESS|FAIL)/.exec(action.type);
        if (!matches) {
          return state;
        }
        const [, requestName, requestState] = matches;
        if (requestState === "REQUEST") {
          return { ...state, [requestName]: true };
        }
        const { [requestName]: prevLoadingState, ...rest } = state;
        return rest;
      }
    );
  },
});

export default loadingSlice.reducer;
