import { Button as MButton, ButtonProps, styled } from "@mui/material";
import React, { memo } from "react";

export interface IonButtonType extends ButtonProps {}

const DEFAULT_COLOR = "primary";
const DEFAULT_SIZE = "medium";

const MStyledButton = styled(MButton)<IonButtonType>(({ theme }) => ({
  borderRadius: Number(theme.shape.borderRadius) * 2,
  textTransform: "none",
  boxShadow: theme.shadows[0],
  "&:hover": {
    boxShadow: theme.shadows[1],
  },
  "&:active": {
    boxShadow: theme.shadows[1],
  },
}));

const IonButton = ({
  children,
  color = DEFAULT_COLOR,
  size = DEFAULT_SIZE,
  ...buttonProps
}: IonButtonType) => {
  return (
    <MStyledButton color={color} {...buttonProps}>
      {children}
    </MStyledButton>
  );
};

export default memo(IonButton);
