import { createSlice } from "@reduxjs/toolkit";
import { User } from "../types/User";

export interface UserState {
  name?: User["name"];
  firstName?: User["firstName"];
  email?: User["email"];
  mobile?: User["mobile"];
  image?: string;
}

const initialState: UserState = {};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => action.type === "CHANGE_USER",
      (state, action) => {
        return { ...state, ...action.payload };
      }
    );
  },
});

export default userSlice.reducer;
