import { configureStore } from "@reduxjs/toolkit";
// import createSagaMiddleware from "redux-saga";
import loadingReducer from "@slices/loadingSlice";
import errorReducer from "@slices/errorSlice";
import userReducer from "@slices/userSlice";
import tenantReducer from "@slices/tenantSlice";

// let sagaMiddleware = createSagaMiddleware();
const middleware: any[] = [];

export const store = configureStore({
  reducer: {
    loading: loadingReducer,
    error: errorReducer,
    user: userReducer,
    tenant: tenantReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      thunk: false,
    }).concat(middleware),
});

// sagaMiddleware.run(rootSaga);

export const functionDispatch = store.dispatch;
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
