import CustomContainer from "@components/screenContainer";
import { Box, Typography } from "@mui/material";
import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: undefined, errorInfo: undefined };
  }

  componentDidCatch(error, errorInfo) {
    console.log({ error, errorInfo });
    this.setState({
      error: error,
      errorInfo: errorInfo,
    });
    // You can also log the error to an error reporting service
    // crashlytics().recordError(error);
    // logErrorToMyService(error, errorInfo);
  }

  render() {
    const { error, errorInfo } = this.state;
    const { children } = this.props;
    if (errorInfo) {
      return (
        <CustomContainer
          maxWidth="lg"
          sx={{
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h4" color="error" align="center">
            Oops, Something Went Wrong.
          </Typography>
          <Typography variant="h6" align="center" sx={{ mt: 2 }}>
            The app ran into a problem and could not continue. We apologise for
            any inconvenience this has caused! Please contact us if this issue
            persists.
          </Typography>
          {process.env.NODE_ENV === "development" && (
            <Box
              sx={{
                mt: 4,
                p: 2,
                borderRadius: 2,
                boxShadow: (theme) => theme.shadows[1],
                background: (theme) => theme.palette.grey[200],
              }}
            >
              <Typography variant="h5">{error && error.toString()}</Typography>
              <Typography variant="caption">
                {errorInfo.componentStack}
              </Typography>
            </Box>
          )}
        </CustomContainer>
      );
    }
    return children;
  }
}

export default ErrorBoundary;
